<template>
  <div class="home zt">
    <!-- 电脑 -->
    <div v-if="!phone">
      <div class="left">
        <div class="d-flex j-a">
          <div
            class="namebox"
            style="width: 100%"
            v-for="(item, index) in treasureBox"
            :key="index"
          >
            <div
              @click="soltGun(index)"
              :class="status == index ? 'active' : ''"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="activity">
        <div class="activity-item">
          <div class="item-main">
            <div
              class="main-box"
              v-for="(items, indexs) in gunArr"
              :key="indexs"
            >
              <router-link
                :to="'/openbox/' + items.id"
                style="text-decoration: none"
              >
                <div
                  class="box-img"
                  :style="{ backgroundImage: 'url(' + items.img + ')' }"
                >
                  <img :src="items.img2" alt />
                </div>
                <div class="box-title">{{ items.name }}</div>
                <div class="box-price">
                  <img src="@/assets/images/home/c.png" alt />
                  <span>{{ items.price }}</span>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 手机 -->
    <div class="phoneactivity" v-else>
      <div
        class="activity-item"
        v-for="(item, index) in treasureBox"
        :key="index"
        ref="activityitem"
      >
        <div class="item-header">{{ item.name }}</div>
        <div class="item-main">
          <div
            class="main-box"
            v-for="(items, indexs) in item.boxs"
            :key="indexs"
          >
            <router-link
              :to="'/openbox/' + items.id"
              style="text-decoration: none"
            >
              <div
                class="box-img"
                :style="{ backgroundImage: 'url(' + items.img + ')' }"
              >
                <img :src="items.img2" alt />
              </div>
              <div class="box-title">{{ items.name }}</div>
              <div class="box-price">
                <img src="@/assets/images/home/c.png" alt />
                <span>{{ items.price }}</span>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getHomeBanner, getTreasureBox } from "../../../api/index";
// import Swiper from "swiper";
export default {
  data() {
    return {
      images: [],
      treasureBox: [],
      cur: true,
      redpacket: false,
      cdkdialog: false,
      screenWidth: document.body.clientWidth,
      isShow: 1,
      gunArr: [],
      status: 0,
      phone: false,
    };
  },
  created() {
    getHomeBanner().then((res) => {
      this.images = res.data.rows;
    });
    getTreasureBox().then((res) => {
      this.treasureBox = res.data.data;
      this.gunArr = this.treasureBox[0].boxs;
    });
  },
  mounted() {
    if (window.innerWidth < 550) {
      this.phone = true;
    }
  },
  beforeRouteLeave(to, from, next) {
    //this.activityscroll = this.$refs.activityitem
    this.scroll = document.querySelector(".el-main").scrollTop;
    next();
  },
  //使用keepAlive缓存组件状态，页面切换路由会触发 activated 钩子函数。在 activated 中将 之前记录的位置赋值给引起滚动条滚动元素的 scrollTop
  activated() {
    this.$nextTick(() => {
      setTimeout(() => {
        // 也可以使用 document.getElementsByClassName("el-table__body-wrapper")[0].scrollTop = this.scroll
        document.querySelector(".el-main").scrollTop = this.scroll;
      }, 0);
    });
  },
  methods: {
    soltGun(id) {
      this.status = id;
      // console.log(id);
      this.gunArr = this.treasureBox[id].boxs;
      // console.log(this.gunArr);
    },
  },
};
</script>

<style scoped lang="scss">
// 点击演示
.active {
  color: yellow;
}

.home {
  width: 1131px;
  height: 460px;
  margin: 30px auto 0;
  background-image: url(~@/assets/images/home/mainallbg.png);
  background-size: 100% 100%;

  .left {
    width: 48%;
    text-align: center;
    color: white;
    font-weight: none;
    font-size: 20px;
    margin: auto;
    overflow: hidden;

    .namebox {
      margin: 12px 0;

      div {
        width: 100px;
        height: 50px;
        line-height: 50px;
        margin: auto;
      }
    }
  }

  .activity {
    margin: 10px auto;
    width: 100%;
    overflow: hidden;
    .activity-item {
      padding-left: 0px;
      padding-right: 0px;
      margin-bottom: 40px;

      @media (min-width: 450px) {
        padding-left: 0%;
        padding-right: 0%;
      }

      .item-main {
        margin-top: 10px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;

        @media (min-width: 450px) {
          grid-template-columns: repeat(5, 1fr);
        }

        .main-box {
          text-align: center;

          .box-img {
            height: 130px;
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (min-width: 450px) {
              height: 200px;
            }

            img {
              height: 80px;
              width: 96px;

              @media (min-width: 450px) {
                width: 200px;
                height: 166px;
              }
            }
          }

          .box-title {
            width: 100px;
            height: 37px;
            margin: auto;
            text-align: center;
            line-height: 32px;
            color: #fff;
            font-size: 12px;
            font-weight: none;
            // background-image: url(~@/assets/images/home/backimg.png);
            background-size: 100% 100%;

            @media (min-width: 450px) {
              width: 120px;
              height: 40px;
              line-height: 34px;
              font-size: 14px;
            }
          }

          .box-price {
            width: 50px;
            height: 20px;
            margin: 0px auto;
            display: flex;
            align-items: center;

            @media (min-width: 450px) {
              width: 60px;
              height: 26px;
            }

            img {
              width: 16px;
              height: 16px;

              @media (min-width: 450px) {
                width: 18px;
                height: 18px;
              }
            }

            span {
              padding-left: 6px;
              color: #fff;
              font-size: 12px;

              @media (min-width: 450px) {
                font-size: 14px;
                padding-left: 10px;
              }
            }
          }
        }
      }
    }
  }

  .footer {
    margin-top: 8px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;

    @media (min-width: 450px) {
      grid-template-columns: repeat(5, 1fr);
    }

    .active {
      margin: auto;
      width: 130px;
      height: 184px;

      @media (min-width: 450px) {
        width: 160px;
        height: 200px;
      }

      img {
        width: 130px;
        height: 108px;

        @media (min-width: 450px) {
          width: 160px;
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .home {
    width: 100%;
    height: 460px;
    margin: 30px auto;
    background-image: none;
    color: #fff;
    .phoneactivity {
      margin-bottom: 50px;
      padding-bottom: 60px;
    }
    .activity-item {
      width: 90%;
      margin: auto;
      overflow: scroll;

      .item-header {
        text-align: center;
        font-size: 22px;
        margin: 20px auto;
        background-image: url("../../../assets/images/openbox/shipin.png");
        background-size: 100% 100%;
      }

      .item-main {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 93%;
        margin: auto;

        .main-box {
          width: 48%;

          .box-title,
          .box-price {
            width: 90%;
            text-align: center;
            color: #fff !important;
          }
        }

        .box-img {
          width: 90%;
          height: 145px;
          background-size: 100% 100%;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>